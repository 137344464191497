import React, { useEffect } from 'react'
import AppStore from '../../assets/appstore_logos/AppStore.png'
import MicrosoftStore from '../../assets/appstore_logos/Microsoft.png'
import PlayStore from '../../assets/appstore_logos/PlayStore.png'
import ResponsiveDesign from '../../assets/appstore_logos/responsiveDesign.webp'
import BallardFoodBank from '../../assets/partner_logos/BallardFoodBank.png'
import FamilyWorksFoodBank from '../../assets/partner_logos/FamilyWorksFoodBank.jpg'
import KrogerZeroHungerZeroWaste from '../../assets/partner_logos/Kroger_zhzw.png'
import Logo from '../../assets/platform_logos/Logo.png'

import {
 Dialog,
 DialogPanel,
 DialogTitle,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Popover,
 PopoverButton,
 PopoverGroup,
 PopoverPanel,
 Tab,
 TabGroup,
 TabList,
 TabPanel,
 TabPanels,
 Transition,
 TransitionChild,
} from '@headlessui/react'
import {
 Bars3Icon,
 BellIcon,
 EllipsisVerticalIcon,
 MagnifyingGlassIcon,
 ShoppingBagIcon,
 ShoppingCartIcon,
 UserIcon,
 XMarkIcon,
} from '@heroicons/react/24/outline'
import {
 BellIcon as BellIconSolid,
 ShoppingCartIcon as ShoppingCartIconSolid,
 UserIcon as UserIconSolid,
} from '@heroicons/react/24/solid'
import { Link, useLocation, useNavigation } from '@remix-run/react'
import { Fragment, useState } from 'react'
import { useAuth, useUser } from '../../../lib/wundergraph'
import Account from './Account'
import AdminMenu from './AdminMenu'
import CartMenu from './CartMenu'
import MainMenu from './MainMenu'
import {
 default as ProfileMenu,
 default as ProfileSlideOver,
} from './NotificationMenu'

function classNames(...classes) {
 return classes.filter(Boolean).join(' ')
}

export default function Header({
 signupUrl,
 user,
 userProfile,
 minioReverseProxyURL,
}) {
 const [open, setOpen] = useState(false)
 const [notificationOpen, setNotificationOpen] = useState(false)
 const [profileOpen, setProfileOpen] = useState(false)
 const [cartOpen, setCartOpen] = useState(false)
 const location = useLocation()
 const { login, logout } = useAuth()
 const { state } = useNavigation()

 const [isAdminPage, setIsAdminPage] = useState(
  location.pathname.includes('/administration')
 )

 useEffect(() => {
  setIsAdminPage(location.pathname.includes('/administration'))
 }, [location.pathname])

 const toggleMainMenuOpenState = () => {
  setOpen(!open)
 }

 const toggleProfileOpenState = () => {
  setProfileOpen(!profileOpen)
 }

 return (
  <div className="bg-forestGreen-950">
   <div className="mx-auto h-14 max-w-6xl">
    <nav aria-label="Top" className="mx-auto px-4">
     <div className="border-gray-900">
      <div className="flex h-14 items-center justify-between">
       {/* Hamburger main menu */}
       <div className="flex flex-1 items-center">
        <button
         type="button"
         className="text-white-400 -ml-1 rounded-md bg-forestGreen-950 p-2"
         onClick={() => (state !== 'loading' ? setOpen(true) : '')}
        >
         <span className="sr-only">Open menu</span>
         <Bars3Icon
          className="h-7 w-7 stroke-white stroke-2"
          aria-hidden="true"
         />
        </button>
       </div>

       {/* Hamburger main menu */}
       <Transition show={open}>
        <Dialog className="relative z-30" onClose={setOpen}>
         <TransitionChild
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
         >
          <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
         </TransitionChild>

         <div className="fixed inset-0 z-30 flex overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex min-w-80 max-w-80">
           <TransitionChild
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
           >
            <DialogPanel className="pointer-events-auto">
             <div className="flex h-full flex-col rounded-r-3xl pt-14 shadow-xl">
              <div className="absolute top-0 h-14 w-full rounded-tr-3xl bg-forestGreen-950 px-4 py-4">
               <div className="flex items-start justify-between">
                <DialogTitle className="text-md px-2 font-bold leading-6 text-white">
                 {isAdminPage ? 'Administration Menu' : 'Menu'}
                </DialogTitle>
                <div className="ml-3 flex h-7 items-center">
                 <button
                  type="button"
                  className="relative -m-2 p-2 text-white hover:text-gray-100"
                  onClick={() => setOpen(false)}
                 >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                 </button>
                </div>
               </div>
              </div>
              {isAdminPage ? (
               <AdminMenu toggleOpenState={toggleMainMenuOpenState} />
              ) : (
               <MainMenu toggleOpenState={toggleMainMenuOpenState} />
              )}
             </div>
            </DialogPanel>
           </TransitionChild>
          </div>
         </div>
        </Dialog>
       </Transition>

       {/* Logo */}
       <Link to="/" className="flex">
        <span className="sr-only">Leftovers.today, Inc.</span>
        <img className="h-10 w-auto" src={Logo} alt="" />
       </Link>

       {/* Notifications menu */}
       <div className="flex flex-1 items-center justify-end">
        {user && (
         <button
          onClick={() => {
           state !== 'loading' ? setNotificationOpen(true) : ''
          }}
          className="p-0.5 text-white"
         >
          <BellIcon className="h-6 w-6" aria-hidden="true" />
         </button>
        )}

        {/* Notifications */}

        <Transition show={notificationOpen}>
         <Dialog className="relative z-30" onClose={setNotificationOpen}>
          <TransitionChild
           enter="ease-in-out duration-300"
           enterFrom="opacity-0"
           enterTo="opacity-100"
           leave="ease-in-out duration-300"
           leaveFrom="opacity-100"
           leaveTo="opacity-0"
          >
           <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          Í
          <div className="fixed inset-0 overflow-hidden">
           <div className="pointer-events-none fixed inset-y-0 right-0 flex min-w-80 max-w-80">
            <TransitionChild
             enter="transform transition ease-in-out duration-300"
             enterFrom="translate-x-full"
             enterTo="translate-x-0"
             leave="transform transition ease-in-out duration-300"
             leaveFrom="translate-x-0"
             leaveTo="translate-x-full"
            >
             <DialogPanel className="pointer-events-auto">
              <div className="flex h-full flex-col rounded-l-3xl pt-14 shadow-xl">
               <div className="absolute top-0 h-14 w-full rounded-tl-3xl bg-forestGreen-950 px-4 py-4">
                <div className="flex items-start justify-between">
                 <DialogTitle className="text-md px-2 font-bold leading-6 text-white">
                  Notifications
                 </DialogTitle>

                 <div className="ml-3 flex h-7 items-center">
                  <button
                   type="button"
                   className="relative -m-2 p-2 text-white hover:text-gray-100"
                   onClick={() => setNotificationOpen(false)}
                  >
                   <span className="absolute -inset-0.5" />
                   <span className="sr-only">Close panel</span>
                   <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                 </div>
                </div>
               </div>
               {/* Main */}
               <ProfileMenu />
              </div>
             </DialogPanel>
            </TransitionChild>
           </div>
          </div>
         </Dialog>
        </Transition>

        <button
         onClick={() => {
          state !== 'loading' ? setProfileOpen(true) : ''
         }}
         className="p-2 text-white lg:p-2"
        >
         {user ? (
          <UserIconSolid className="h-6 w-6" aria-hidden="true" />
         ) : (
          <UserIcon className="h-6 w-6" aria-hidden="true" />
         )}
        </button>

        {/* Profile menu transition */}
        <Transition show={profileOpen}>
         <Dialog className="relative z-30" onClose={setProfileOpen}>
          <TransitionChild
           enter="ease-in-out duration-300"
           enterFrom="opacity-0"
           enterTo="opacity-100"
           leave="ease-in-out duration-300"
           leaveFrom="opacity-100"
           leaveTo="opacity-0"
          >
           <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          Í
          <div className="fixed inset-0 overflow-hidden">
           <div className="pointer-events-none fixed inset-y-0 right-0 flex min-w-80 max-w-80">
            <TransitionChild
             enter="transform transition ease-in-out duration-300"
             enterFrom="translate-x-full"
             enterTo="translate-x-0"
             leave="transform transition ease-in-out duration-300"
             leaveFrom="translate-x-0"
             leaveTo="translate-x-full"
            >
             <DialogPanel className="pointer-events-auto">
              <div className="flex h-full flex-col rounded-l-3xl pt-14 shadow-xl">
               <div className="absolute top-0 h-14 w-full rounded-tl-3xl bg-forestGreen-950 px-4 py-4">
                <div className="flex items-start justify-between">
                 <DialogTitle className="text-md px-2 font-bold leading-6 text-white">
                  Account
                 </DialogTitle>

                 <div className="ml-3 flex h-7 items-center">
                  <button
                   type="button"
                   className="relative -m-2 p-2 text-white hover:text-gray-100"
                   onClick={() => setProfileOpen(false)}
                  >
                   <span className="absolute -inset-0.5" />
                   <span className="sr-only">Close panel</span>
                   <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                 </div>
                </div>
               </div>
               {/* Main */}
               <Account
                toggleOpenState={toggleProfileOpenState}
                signupUrl={signupUrl}
                userProfile={userProfile}
                minioReverseProxyURL={minioReverseProxyURL}
                user={user}
               />
              </div>
             </DialogPanel>
            </TransitionChild>
           </div>
          </div>
         </Dialog>
        </Transition>

        {/* Cart and seperator */}
        {/* {user && (
                <>
                  <span className="m-1 h-6 w-px bg-white" aria-hidden="true" />
                  <div className="ml-3 flow-root">
                    <button
                      onClick={() => setCartOpen(true)}
                      className="group -m-2 flex items-center p-1"
                    >
                      <ShoppingCartIcon
                        className="h-6 w-6 flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                      <span className="ml-2 text-sm font-medium text-white">
                        10
                      </span>
                    </button>
                  </div>
                </>
              )} */}

        {/* Cart menu transition */}
        {/* <Transition show={cartOpen}>
                <Dialog className="relative z-30" onClose={setCartOpen}>
                  <TransitionChild
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </TransitionChild>

                  <div className="fixed inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex min-w-80 max-w-80 lg:min-w-96 lg:max-w-96">
                      <TransitionChild
                        enter="transform transition ease-in-out duration-300"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-300"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <DialogPanel className="pointer-events-auto">
                          <div className="flex h-full flex-col bg-white pt-14 shadow-xl">
                            <div className="absolute top-0 h-14 w-full bg-forestGreen-950 px-4 py-4 sm:px-6">
                              <div className="flex items-start justify-between">
                                <DialogTitle className="text-md font-normal leading-6 text-white">
                                  Shopping cart
                                </DialogTitle>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="relative -m-2 p-2 text-white hover:text-gray-100"
                                    onClick={() => setCartOpen(false)}
                                  >
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <CartMenu />
                            <div className="absolute bottom-0 w-full justify-center bg-white text-center text-xs text-gray-500">
                              <p>
                                or{' '}
                                <button
                                  type="button"
                                  className="pb-3 font-medium text-warmTerracota-700 hover:text-warmTerracota-600"
                                  onClick={() => setCartOpen(false)}
                                >
                                  Continue Shopping
                                  <span aria-hidden="true"> &rarr;</span>
                                </button>
                              </p>
                            </div>
                          </div>
                        </DialogPanel>
                      </TransitionChild>
                    </div>
                  </div>
                </Dialog>
              </Transition> */}
       </div>
      </div>
     </div>
    </nav>
   </div>
  </div>
 )
}
