/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
 Dialog,
 DialogPanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Transition,
 TransitionChild,
} from '@headlessui/react'
import {
 ArrowLeftStartOnRectangleIcon,
 ArrowRightEndOnRectangleIcon,
 Bars3Icon,
 BellIcon,
 BuildingStorefrontIcon,
 CalendarIcon,
 ChartBarIcon,
 ChartPieIcon,
 Cog6ToothIcon,
 DocumentDuplicateIcon,
 EnvelopeIcon,
 FolderIcon,
 HomeIcon,
 LightBulbIcon,
 QuestionMarkCircleIcon,
 ShoppingBagIcon,
 TrophyIcon,
 UsersIcon,
 WrenchScrewdriverIcon,
 XMarkIcon,
} from '@heroicons/react/24/outline'
import {
 CheckBadgeIcon,
 ChevronDownIcon,
 MagnifyingGlassIcon,
 ScaleIcon,
 ShieldCheckIcon,
 ShieldExclamationIcon,
 ShoppingBagIcon as ShoppingBagIconSolid,
 UserCircleIcon,
} from '@heroicons/react/24/solid'
import { Link, redirect, useNavigate, useRevalidator } from '@remix-run/react'
import { userInfo } from 'os'
import { hrtime } from 'process'
import React, { Fragment, useState } from 'react'
import { useAuth, useUser } from '../../../lib/wundergraph'

const accountSettings = [
 { name: 'Orders', href: '/orders', icon: ShoppingBagIcon, current: false },
 {
  name: 'Account settings',
  href: '/profile',
  icon: Cog6ToothIcon,
  current: false,
 },
 {
  name: 'Invite friends and family',
  href: '/',
  icon: EnvelopeIcon,
  current: false,
 },
]

const adminSettings = [
 {
  name: 'Administration',
  href: '',
  // href: '/administration',
  icon: WrenchScrewdriverIcon,
  current: false,
 },
 // {
 //   name: 'Marketplace',
 //   href: '',
 //   icon: BuildingStorefrontIcon,
 //   current: false,
 // },
]

function classNames(...classes) {
 return classes.filter(Boolean).join(' ')
}

export default function Account({
 toggleOpenState,
 signupUrl,
 user,
 userProfile,
 minioReverseProxyURL,
}) {
 // const { data: user } = useUser()
 const { login, logout } = useAuth()
 const navigate = useNavigate()
 const revalidator = useRevalidator()

 function handleLogin() {
  login('keycloak')
 }

 var updatedURL
 if (
  user &&
  userProfile.data.strapi_userProfiles.data.length != 0 &&
  userProfile.data.strapi_userProfiles.data[0].attributes.photo.data != null
 ) {
  var originalURL
  if (
   userProfile.data.strapi_userProfiles.data[0].attributes.photo.data.attributes
    .formats.small
  )
   originalURL = new URL(
    userProfile.data.strapi_userProfiles.data[0].attributes.photo.data.attributes.formats.small.url
   )
  else
   originalURL = new URL(
    userProfile.data.strapi_userProfiles.data[0].attributes.photo.data.attributes.url
   )
  updatedURL = new URL(minioReverseProxyURL)
  updatedURL.pathname = originalURL.pathname
 }

 return (
  <div className="top-0 h-full w-full min-w-80 max-w-80 overflow-y-scroll rounded-bl-3xl bg-white pt-2">
   <div className="px-1">
    {user && (
     <>
      <div className="grid grid-cols-3 items-center pl-5 pt-3 align-middle">
       <div className="justify-left col-span-1">
        {updatedURL ? (
         <>
          <img
           className="h-24 w-24 rounded-2xl object-cover"
           src={updatedURL.toString()}
          />
         </>
        ) : (
         <UserCircleIcon className="aspect-1 w-24 rounded-2xl object-cover text-gray-300" />
        )}
       </div>
       <div className="justify-left col-span-2 pl-2.5">
        <h3 className="text-ellipsis text-sm font-semibold text-gray-900">
         {userProfile.data &&
         userProfile.data.strapi_userProfiles.data.length != 0
          ? userProfile.data.strapi_userProfiles.data[0].attributes.firstname
          : 'New'}
        </h3>
        <h3 className="text-ellipsis text-sm font-semibold text-gray-900">
         {userProfile.data &&
         userProfile.data.strapi_userProfiles.data.length != 0
          ? userProfile.data.strapi_userProfiles.data[0].attributes.lastname
          : 'User'}
        </h3>
        {/* <span className="text-ellipsis text-xs text-gray-400">
                  <div className="flex flex-row text-xs italic text-gray-400 items-center align-middle">
                    {userProfile.data.strapi_userProfiles.data[0].attributes.email}
                  </div>
                </span> */}
       </div>
      </div>

      <div className="relative ml-5 mr-5 mt-5 flex max-h-28 min-h-28 flex-col justify-center space-y-3 rounded-2xl bg-forestGreen-950 px-5 text-sm font-bold text-white">
       <div className="flex flex-row">
        <TrophyIcon className="h-5 w-6 pr-1 text-softApricot-800" />{' '}
        {userProfile.data &&
        userProfile.data.strapi_userProfiles.data.length != 0 &&
        userProfile.data.strapi_userProfiles.data[0].attributes.itemsSaved
         ? userProfile.data.strapi_userProfiles.data[0].attributes.itemsSaved
         : '0'}{' '}
        items rescued
       </div>
       <div className="flex flex-row">
        <ScaleIcon className="h-5 w-6 pr-1 text-softApricot-800" />
        {userProfile.data.strapi_userProfiles.data.length != 0 &&
        userProfile.data.strapi_userProfiles.data[0].attributes.lbsSaved
         ? userProfile.data.strapi_userProfiles.data[0].attributes.lbsSaved
         : '0'}{' '}
        lbs of food saved
       </div>
       <div className="pl-1 text-xs font-light italic">
        {userProfile.data &&
        userProfile.data.strapi_userProfiles.data.length != 0 &&
        userProfile.data.strapi_userProfiles.data[0].attributes.createdAt
         ? 'Member since ' +
           new Date(
            userProfile.data.strapi_userProfiles.data[0].attributes.createdAt
           ).toLocaleDateString('default', { month: 'long' }) +
           ' ' +
           new Date(
            userProfile.data.strapi_userProfiles.data[0].attributes.createdAt
           ).getFullYear()
         : ''}
       </div>
      </div>
     </>
    )}

    {/* Sidebar component, swap this element with another sidebar if you like */}
    <div className="flex grow flex-col gap-y-6 overflow-y-scroll bg-white px-5 pb-6 pt-2">
     <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-5">
       {!user && (
        <>
         <li className="pt-2">
          <div className="relative flex max-h-32 min-h-32 flex-col justify-center space-y-2 rounded-2xl bg-forestGreen-950 px-5 text-sm font-bold text-white">
           <div className="flex flex-row pb-1">
            <CheckBadgeIcon className="h-5 w-6 pr-1 text-softApricot-800" />{' '}
            Join now & rescue free food
           </div>
           <div className="flex flex-row">
            <ShoppingBagIconSolid className="h-5 w-6 pr-1 text-softApricot-800" />
            Direct donations from stores
           </div>
           <div className="flex flex-row justify-center">
            <button
             onClick={() => (window.location.href = signupUrl)}
             className="mt-0.5 max-w-24 rounded-md bg-warmTerracota-700 px-3.5 py-1.5 text-sm text-white shadow-sm hover:bg-warmTerracota-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
             Sign Up
            </button>
           </div>
          </div>
         </li>
         <li className="border-gray-200">
          <div className="pb-2 text-xs font-semibold leading-6 text-gray-400">
           Returning users
          </div>
          <button
           onClick={() => {
            handleLogin()
           }}
           className="group -mx-2 flex gap-x-3 rounded-md px-2 text-sm font-semibold leading-5 text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700"
          >
           <ArrowRightEndOnRectangleIcon
            className="h-5 w-5 shrink-0 text-gray-400 group-hover:text-warmTerracota-700"
            aria-hidden="true"
           />
           Sign In
          </button>
         </li>
        </>
       )}

       {user && (
        <li>
         {/* <div className="text-xs font-semibold leading-6 text-gray-400">
                    Account
                  </div> */}
         <ul role="list" className="-mx-2">
          {accountSettings.map((item) => (
           <li key={item.name}>
            <Link
             to={item.href}
             prefetch="viewport"
             onClick={() => {
              if (!item.name.includes('Invite')) {
               toggleOpenState()
              } else {
               toggleOpenState()
               try {
                navigator.share({
                 title: document.title,
                 text: 'Check out Leftovers.today! Food rescue, simplified.',
                 url: 'https://leftovers.today',
                })
               } catch (error) {
                console.log('Error sharing:', error)
               }
              }
             }}
             className={classNames(
              item.current
               ? 'bg-gray-50 text-warmTerracota-700'
               : 'text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700',
              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5'
             )}
            >
             <item.icon
              className={classNames(
               item.current
                ? 'text-warmTerracota-700'
                : 'text-gray-400 group-hover:text-warmTerracota-700',
               'h-5 w-5 shrink-0'
              )}
              aria-hidden="true"
             />
             {item.name}
            </Link>
           </li>
          ))}
         </ul>
        </li>
       )}

       {user && user.roles?.find((element) => element == 'adminAccess') && (
        <li>
         <div className="text-xs font-semibold leading-6 text-gray-400">
          Food Rescue Logistics
         </div>
         <ul role="list" className="-mx-2">
          {adminSettings.map((item) => (
           <li key={item.name}>
            <Link
             to={'/administrator/' + user.userId}
             prefetch="viewport"
             onClick={() => toggleOpenState()}
             className={classNames(
              item.current
               ? 'bg-gray-50 text-warmTerracota-700'
               : 'text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700',
              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5'
             )}
            >
             <item.icon
              className={classNames(
               item.current
                ? 'text-warmTerracota-700'
                : 'text-gray-400 group-hover:text-warmTerracota-700',
               'h-5 w-5 shrink-0'
              )}
              aria-hidden="true"
             />
             {item.name}
            </Link>
           </li>
          ))}
         </ul>
        </li>
       )}

       {user && (
        <li className="mt-auto border-t border-gray-200 pt-5">
         <button
          // Close the menu, then sign out, then navigate to the homepage, then revalidate the page
          onClick={() => {
           toggleOpenState()
           logout({ logoutOpenidConnectProvider: true }).finally(() => {
            navigate('/'), revalidator.revalidate()
           })
          }}
          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5 text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700"
         >
          <ArrowLeftStartOnRectangleIcon
           className="h-5 w-5 shrink-0 text-gray-400 group-hover:text-warmTerracota-700"
           aria-hidden="true"
          />
          Sign Out
         </button>
        </li>
       )}
      </ul>
     </nav>
    </div>
   </div>
  </div>
 )
}
