/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
 Dialog,
 DialogPanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Transition,
 TransitionChild,
} from '@headlessui/react'
import {
 ArrowLeftStartOnRectangleIcon,
 ArrowRightEndOnRectangleIcon,
 Bars3Icon,
 BellIcon,
 BuildingStorefrontIcon,
 CalendarIcon,
 ChartBarSquareIcon,
 ChartPieIcon,
 Cog6ToothIcon,
 DocumentDuplicateIcon,
 DocumentTextIcon,
 EnvelopeIcon,
 FolderIcon,
 HomeIcon,
 InformationCircleIcon,
 LightBulbIcon,
 ListBulletIcon,
 LockClosedIcon,
 NewspaperIcon,
 QuestionMarkCircleIcon,
 ShoppingBagIcon,
 TrophyIcon,
 UserGroupIcon,
 UsersIcon,
 XMarkIcon,
} from '@heroicons/react/24/outline'
import {
 CheckBadgeIcon,
 ChevronDownIcon,
 CurrencyDollarIcon,
 MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import { Link, redirect, useNavigate, useParams } from '@remix-run/react'
import React, { Fragment, useState } from 'react'
import { useAuth, useUser } from '../../../lib/wundergraph'
import HashtagLogo from '../../assets/platform_logos/HashtagLogo.png'

const navigation = [
 {
  name: 'Overview',
  href: '/overview',
  icon: ChartBarSquareIcon,
  current: false,
 },
]
const support = [
 {
  id: 1,
  name: 'How Leftovers.today works',
  href: '#',
  icon: LightBulbIcon,
  initial: 'H',
  current: false,
 },
 {
  id: 2,
  name: 'Help center',
  href: '#',
  initial: 'H',
  icon: QuestionMarkCircleIcon,
  current: false,
 },
]

const companyInfo = [
 {
  id: 1,
  name: 'Dashboard',
  href: '/marketplace/dashboard',
  icon: DocumentTextIcon,
  initial: 'T',
  current: false,
 },
 {
  id: 2,
  name: 'Inventory',
  href: '/marketplace/inventory',
  initial: 'I',
  icon: ListBulletIcon,
  current: false,
 },
 {
  id: 3,
  name: 'Orders',
  href: '/marketplace/orders',
  icon: ShoppingBagIcon,
  initial: 'O',
  current: false,
 },
 {
  id: 4,
  name: 'Customers',
  href: '/marketplace/customers',
  initial: 'C',
  icon: UserGroupIcon,
  current: false,
 },
]

const apps = [
 {
  id: 1,
  name: 'App Store',
  href: '#',
  initial: 'A',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6em"
    height="6em"
    viewBox="0 0 24 24"
   >
    <path
     fill="none"
     stroke="currentColor"
     strokeLinecap="round"
     strokeLinejoin="round"
     strokeWidth="1.5"
     d="M15.177 5.177c-1.083 1.084-3.14.785-3.14.785s-.298-2.056.786-3.14c1.083-1.083 3.14-.784 3.14-.784s.298 2.056-.786 3.14M4 14.06c0 3.281 2.196 7.05 4.419 7.847c.77.276 1.567-.105 2.229-.58c.502-.36 1.11-.703 1.602-.703c.49 0 1.1.343 1.601.703c.662.475 1.459.856 2.23.58c1.579-.566 3.145-2.633 3.919-4.96c-1.5-.43-2.594-1.786-2.594-3.393c0-1.472.919-2.734 2.226-3.265C18.79 8.793 17.414 8 15.859 8c-.804 0-1.544.324-2.14.713c-.954.62-1.985.62-2.938 0C10.184 8.324 9.445 8 8.641 8C6.077 8 4 10.155 4 14.06"
     color="currentColor"
    />
   </svg>
  ),
  current: false,
 },
 {
  id: 2,
  name: 'Google Play',
  href: '#',
  initial: 'G',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 14 14"
   >
    <path
     fill="none"
     stroke="currentColor"
     strokeLinecap="round"
     strokeLinejoin="round"
     d="M.859 11.981V1.741c0-.672.79-1.098 1.434-.771L12.37 6.09c.662.336.662 1.207 0 1.543l-10.077 5.12c-.644.327-1.434-.099-1.434-.772M9.23 9.23l-8.1-8.101m8.1 3.364l-8.1 8.1"
    />
   </svg>
  ),
  current: false,
 },
 {
  id: 3,
  name: 'Microsoft Store',
  href: '#',
  initial: 'M',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6em"
    height="6em"
    viewBox="0 0 20 20"
   >
    <path
     fill="currentColor"
     d="M9.5 7v3h-3V7zm0 7v-3h-3v3zm4-7v3h-3V7zm0 7v-3h-3v3zM7 4V2.5A1.5 1.5 0 0 1 8.5 1h3A1.5 1.5 0 0 1 13 2.5V4h4.5a.5.5 0 0 1 .5.5v10a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 2 14.5v-10a.5.5 0 0 1 .5-.5zm1-1.5V4h4V2.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5m-5 12A1.5 1.5 0 0 0 4.5 16h11a1.5 1.5 0 0 0 1.5-1.5V5H3z"
    />
   </svg>
  ),
  current: false,
 },
]

const social = [
 {
  name: 'Facebook',
  href: '#',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="currentColor"
    viewBox="0 0 24 24"
   >
    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
   </svg>
  ),
 },
 {
  name: 'Instagram',
  href: '#',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="currentColor"
    viewBox="0 0 24 24"
   >
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
   </svg>
  ),
 },
 {
  name: 'TikTok',
  href: '#',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className="h-5 w-5"
   >
    {/* <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
    <path
     fill="currentColor"
     d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
    />
   </svg>
  ),
 },
 {
  name: 'Pinterest',
  href: '#',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="currentColor"
    viewBox="0 0 24 24"
   >
    <path
     d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
     fillRule="evenodd"
     clipRule="evenodd"
    />
   </svg>
  ),
 },
 {
  name: 'LinkedIn',
  href: '#',
  icon: (props) => (
   <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="currentColor"
    viewBox="0 0 24 24"
   >
    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
   </svg>
  ),
 },
]

function classNames(...classes) {
 return classes.filter(Boolean).join(' ')
}

export default function AdminMenu({ toggleOpenState }) {
 const { login, logout } = useAuth()
 const params = useParams()

 return (
  <div className="top-0 h-full w-full min-w-80 max-w-80 overflow-y-scroll rounded-br-3xl bg-white">
   <div>
    {/* Sidebar component, swap this element with another sidebar if you like */}
    <div className="flex grow flex-col gap-y-6 overflow-y-scroll px-6 pb-6 pt-6">
     <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-5">
       <li>
        <ul role="list" className="-mx-2">
         {navigation.map((item) => (
          <li key={item.name}>
           <Link
            to={'/administration/' + params.vendorIdentifier + item.href}
            prefetch="viewport"
            onClick={toggleOpenState}
            className={classNames(
             item.current
              ? 'bg-gray-50 text-warmTerracota-700'
              : 'text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700',
             'group flex gap-x-3 rounded-md px-2 text-sm font-semibold leading-5'
            )}
           >
            <item.icon
             className={classNames(
              item.current
               ? 'text-warmTerracota-700'
               : 'text-gray-400 group-hover:text-warmTerracota-700',
              'h-5 w-5 shrink-0'
             )}
             aria-hidden="true"
            />
            {item.name}
           </Link>
          </li>
         ))}
        </ul>
       </li>

       {/* Apps */}
       {/* <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Apps
                </div>
                <ul role="list" className="-mx-2">
                  {apps.map((app) => (
                    <li key={app.name}>
                      <a
                        href={app.href}
                        className={classNames(
                          app.current
                            ? 'bg-gray-50 text-warmTerracota-700'
                            : 'text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5'
                        )}
                      >
                        <span
                          className={classNames(
                            app.current
                              ? 'border-warmTerracota-700 text-warmTerracota-700'
                              : 'border-gray-200 text-gray-400 group-hover:border-warmTerracota-700 group-hover:text-warmTerracota-700',
                            'flex h-5 w-5 shrink-0 items-center justify-center rounded-lg bg-white text-[0.625rem] font-medium'
                          )}
                        >
                          <app.icon
                            className={classNames(
                              app.current
                                ? 'text-warmTerracota-700'
                                : 'text-gray-400 group-hover:text-warmTerracota-700',
                              'shrink-0'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                        <span className="truncate">{app.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </li> */}

       <li>
        <div className="text-xs font-semibold leading-6 text-gray-400">
         Marketplace
        </div>
        <ul role="list" className="-mx-2">
         {companyInfo.map((item) => (
          <li key={item.name}>
           <Link
            to={'/administration/' + params.vendorIdentifier + item.href}
            prefetch="viewport"
            onClick={() => toggleOpenState()}
            className={classNames(
             item.current
              ? 'bg-gray-50 text-warmTerracota-700'
              : 'text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700',
             'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5'
            )}
           >
            <item.icon
             className={classNames(
              item.current
               ? 'text-warmTerracota-700'
               : 'text-gray-400 group-hover:text-warmTerracota-700',
              'h-5 w-5 shrink-0'
             )}
             aria-hidden="true"
            />
            {item.name}
           </Link>
          </li>
         ))}
        </ul>
       </li>

       {/* Support */}
       <li>
        <div className="text-xs font-semibold leading-6 text-gray-400">
         Support
        </div>
        <ul role="list" className="-mx-2">
         {support.map((supportItems) => (
          <li key={supportItems.name}>
           <a
            href={supportItems.href}
            className={classNames(
             supportItems.current
              ? 'bg-gray-50 text-warmTerracota-700'
              : 'text-gray-700 hover:bg-gray-50 hover:text-warmTerracota-700',
             'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5'
            )}
           >
            <span
             className={classNames(
              supportItems.current
               ? 'text-warmTerracota-700'
               : 'text-gray-400 group-hover:text-warmTerracota-700',
              'h-5 w-5 shrink-0'
             )}
            >
             <supportItems.icon
              className={classNames(
               supportItems.current
                ? 'text-warmTerracota-700'
                : 'text-gray-400 group-hover:text-warmTerracota-700',
               'h-5 w-5 shrink-0'
              )}
              aria-hidden="true"
             />
            </span>
            <span className="truncate">{supportItems.name}</span>
           </a>
          </li>
         ))}
        </ul>
       </li>

       {/* Social */}
       {/* <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Social
                </div>
                <div className="justify-left mt-4 flex w-fit space-x-10 pl-1">
                  {social.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
                <img
                  src={HashtagLogo}
                  className="mx-auto mt-5 flex max-w-40 justify-center"
                />
              </li> */}
      </ul>
     </nav>
    </div>
   </div>
  </div>
 )
}
