import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 Transition,
} from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import React from 'react'

function classNames(...classes) {
 return classes.filter(Boolean).join(' ')
}

export default function NotificationMenu() {
 return (
  <div className="top-0 h-full w-full min-w-80 max-w-80 overflow-y-scroll rounded-bl-3xl bg-white">
   <div className="pb-40">
    <div className="px-6 pt-4 sm:flex sm:items-end sm:px-6">
     <div className="sm:flex-1">
      <div>
       <div className="flex items-center">
        <h3 className="font-bold text-gray-900">Notification-1</h3>
       </div>
       <p className="text-sm text-gray-500">Some message</p>
      </div>
     </div>
    </div>
   </div>
  </div>
 )
}
